<template>
  <div class="order">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:5.15rem">
      <img
        src="../../assets/image/order/banner.png"
        @click="toPath('/try')"
        style="width:19.2rem;height:5.15rem"
      />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/order/model0-title.png"
        style="width: 8.02rem;height:1.4rem;margin-top:0.36rem;margin-bottom:1.23rem"
      />
      <img src="../../assets/image/order/model0-img.png" style="width: 13.19rem;height:4.09rem;" />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/order/model1-title.png"
        style="width: 9.8rem;height:1.35rem;margin-top:1rem;margin-bottom:0.77rem"
      />
      <img src="../../assets/image/order/model1-img.png" style="width: 10.02rem;height:4.45rem;" />
    </div>
    <div class="model2 column">
      <img
        src="../../assets/image/order/model2.png"
        style="width: 14rem;height:5.98rem;margin-top:1rem;"
      />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>
<style scoped>
.order {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 8.56rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 8.73rem;
  background: #ffffff;
}

.model2 {
  position: relative;
  width: 19.2rem;
  height: 8rem;
}
</style>