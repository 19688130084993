<template>
  <div class="aiot">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.5rem">
      <img src="../../assets/image/aiot/banner.png" @click="toPath('/try')" style="width:19.2rem;height:4.5rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/aiot/model0-title.png"
        style="width: 10.03rem;height:1.49rem;margin-top:0.81rem;margin-bottom:0.64rem"
      />
      <img src="../../assets/image/aiot/model0-img.png" style="width: 13.59rem;height:2.26rem;" />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/aiot/model1.png"
        style="width: 14.08rem;height:6.01rem;margin-top:1rem"
      />
    </div>
    <div class="model2 column">
      <img
        src="../../assets/image/aiot/model2-title.png"
        style="width: 10.59rem;height:1.32rem;margin-top:1rem;margin-bottom:0.53rem"
      />
      <img src="../../assets/image/aiot/model2-img.png" style="width: 10.64rem;height:6.94rem;" />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>
<style scoped>
.aiot {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 6.84rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 7.7rem;
  background: #ffffff;
}

.model2 {
  position: relative;
  width: 19.2rem;
  height: 11.45rem;
}
</style>