<template>
    <div style="padding:0 0.3rem">
        <div class="column"> <img src="../assets/image/phoneTry/phone_title.png"
                style="width:3.96rem;height:0.54rem;margin-top:0.94rem;margin-bottom:0.6rem" />
        </div>
        <div class="fz28 fw700 mb30">选择产品</div>
        <RadioGroup v-model="radio1" class="fz28">
            <Radio name="MOJ数智酒店OMS运营服务系统" class="mb25">MOJ数智酒店OMS运营服务系统</Radio>
            <Radio name="MOMO酒店语音智能AI员工系统" class="mb25">MOMO酒店语音智能AI员工系统</Radio>
            <Radio name="MOMO智能AI对讲系统" class="mb25">MOMO智能AI对讲系统</Radio>
            <Radio name="美寓管家长租公寓管理系统" class="mb25">美寓管家长租公寓管理系统</Radio>
            <Radio name="其他">其他</Radio>
        </RadioGroup>
        <div class="fz28 fw700 mb30 mt50">合作类型</div>
        <RadioGroup v-model="radio2" class="fz28">
            <div class="row mb25">
                <Radio name="酒店" style="margin-right: 1.07rem;">酒店</Radio>
                <Radio name="渠道" style="margin-right: 1.07rem;">渠道</Radio>
                <Radio name="其他">其他</Radio>
            </div>
            <div class="row mb30">
                <Radio name="公寓" style="margin-right: 1.07rem;">公寓</Radio>
                <Radio name="合作伙伴">合作伙伴</Radio>
            </div>

        </RadioGroup>
        <div v-if="radio2 == '酒店'">
            <div class="fz28 mb10">使用的PMS系统</div>
            <Field v-model="pms" style="border-radius: 0.2rem;" :rules="[{ required: true }]" placeholder="填写您当前使用的PMS系统" />
        </div>
        <div class="fz28 fw700 mb30 mt50">联系方式</div>
        <div class="fz28 mb10">姓名</div>
        <Field v-model="name" class="mb36" style="border-radius: 0.2rem;" :rules="[{ required: true }]" placeholder="输入姓名/称呼（必填）" />
        <div class="fz28 mb10">单位</div>
        <Field v-model="company" class="mb36" style="border-radius: 0.2rem;" :rules="[{ required: true }]" placeholder="输入您的公司名称/店名（必填）" />
        <div class="fz28 mb10">城市</div>
        <Field v-model="address" class="mb36" style="border-radius: 0.2rem;" readonly :rules="[{ required: true }]" placeholder="省市区"
            @click="show = true" />
        <div class="fz28 mb10">手机</div>
        <Field v-model="phone" class="mb36" style="border-radius: 0.2rem;" :rules="[{ required: true }]" placeholder="输入您的手机号码（必填）" />
        <div class="fz28 mb10">邮箱</div>
        <Field v-model="email" class="mb36" style="border-radius: 0.2rem;" :rules="[{ required: true }]" placeholder="输入您的邮箱用于接收相关资料" />
        <div class="fz28 mb30">职位</div>
        <RadioGroup v-model="radio3" class="fz28">
            <div class="row mb25">
                <Radio name="负责人" style="margin-right: 1.07rem;">负责人</Radio>
                <Radio name="管理人员" style="margin-right: 1.07rem;">管理人员</Radio>
                <Radio name="运营">运营</Radio>
            </div>
            <div class="row" style="padding-bottom:0.5rem">
                <Radio name="IT" style="margin-right: 1.07rem;">IT</Radio>
                <Radio name="其他">其他</Radio>
            </div>
        </RadioGroup>
        <Button type="primary" style="border-radius: 0.2rem;" block @click="sendEmail">确认提交</Button>
        <div style="height: 0.5rem;"></div>
        <Popup :show="show" position="bottom" @click-overlay="show = false">
            <Area v-model="value" @cancel="show = false" @confirm="chooseAddress" title="选择地址" :area-list="areaList" />
        </Popup>

    </div>
</template>
<script setup>
import { ref } from "vue";
import { getCurrentInstance } from "vue";
import { ElLoading } from "element-plus";
import emailjs from "@emailjs/browser";
import { RadioGroup, Radio, Field, Area, Popup, Button } from 'vant';
import { areaList } from '@vant/area-data';
const { proxy } = getCurrentInstance();
const radio1 = ref("MOJ数智酒店OMS运营服务系统");
const radio2 = ref("酒店");
const radio3 = ref("负责人");
const name = ref();
const phone = ref();
const email = ref();
const company = ref();
const address = ref();
const pms = ref();
const show = ref(false);
function chooseAddress(picker) {
    address.value = picker.selectedOptions[0].text + picker.selectedOptions[1].text + picker.selectedOptions[2].text
    show.value = false
}
function sendEmail() {
    if (name.value == null && phone.value == null && company.value == null) {
        alert("请完善表单");
        return;
    }
    const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)"
    });
    emailjs
        .send(
            "service_yril4of",
            "template_gi40x64",
            {
                radio1: radio1.value,
                radio2: radio2.value,
                pms: pms.value,
                radio3: radio3.value,
                name: name.value,
                phone: phone.value,
                address: address.value,
                email: email.value,
                company: company.value,
                sendemail: "info@moj100.com"
            },
            "9kSY0dkq3_Uq8lS7a"
        )
        .then(
            result => {
                loading.close();
                console.log("SUCCESS!", result.text);
                alert("申请成功");
            },
            error => {
                loading.close();
                console.log("FAILED...", error.text);
                alert("申请失败");
            }
        );
}
// function hhhh() {
//   Email.send({
//     Host : "smtp.163.com",
//     Username : "qzxt@163.com",
//     Password : "IRUHPSOOKGDNHDHG",
//     To : 'y15195965752@163.com',
//     From : "qzxt@163.com",
//     Subject : "邮件主题",
//     Body : "<h1>邮件内容</h1>"
//   }).then(message => alert(message));
// }
</script>
