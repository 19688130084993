<template>
  <div class="middleground">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.5rem">
      <img src="../../assets/image/middleground/banner.png"  @click="toPath('/try')" style="width:19.2rem;height:4.5rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/middleground/model0-title.png"
        style="width: 10.1rem;height:1.42rem;margin-top:1rem;margin-bottom:1.1rem"
      />
      <img
        src="../../assets/image/middleground/model0-img.png"
        style="width: 13.5rem;height:2.72rem;"
      />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/middleground/model1-title.png"
        style="width: 10.9rem;height:1.28rem;margin-top:1rem;margin-bottom:0.6rem"
      />
      <img
        src="../../assets/image/middleground/model1-img.png"
        style="width: 10.62rem;height:4.72rem;"
      />
    </div>
    <div class="model2 column">
      <img
        src="../../assets/image/middleground/model2.png"
        style="width: 11.78rem;height:8.92rem;margin-top:1rem;"
      />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>
<style scoped>
.middleground {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 7.04rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 8.73rem;
  background: #ffffff;
}

.model2 {
  position: relative;
  width: 19.2rem;
  height: 10.37rem;
}
</style>