<template>
  <div class="face">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.50rem">
      <img src="../../assets/image/face/banner.png"  @click="toPath('/try')" style="width:19.2rem;height:4.50rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/face/model0-title.png"
        style="width: 12.88rem;height:1.81rem;margin-top:0.8rem;margin-bottom:1.43rem"
      />
      <img src="../../assets/image/face/model0-img.png" style="width: 13.56rem;height:2.6rem;" />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/face/model1-title.png"
        style="width: 7.93rem;height:1.16rem;margin-top:0.86rem;margin-bottom:0.87rem"
      />
      <img src="../../assets/image/face/model1-img.png" style="width: 12.52rem;height:5.13rem;" />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>
<style scoped>
.face {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 7.26rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 8.8rem;
  background: #ffffff;
}
</style>