<template>
  <div class="momo">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.51rem">
      <img src="../../assets/image/momo/banner.png" style="width:19.2rem;height:4.51rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/momo/model0.png"
        style="width: 8.63rem;height:3.28rem;margin-top:1rem;"
      />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/momo/model1-title.png"
        style="width: 3.54rem;height:0.9rem;margin-top:1rem;margin-bottom:1.59rem"
      />
      <img src="../../assets/image/momo/model1-img.png" style="width: 13.19rem;height:2.21rem;" />
    </div>
    <div class="model2 column">
      <img
        src="../../assets/image/momo/model2-title.png"
        style="width: 6.13rem;height:1.5rem;margin-top:1.63rem;margin-bottom:0.6rem"
      />
      <img src="../../assets/image/momo/model2-img.png" style="width: 14rem;height:4.83rem;" />
    </div>
    <div class="model3 column">
      <img
        src="../../assets/image/momo/model3-bg.png"
        style="width: 19.2rem;height:4.59rem;"
      />
      <img src="../../assets/image/momo/model3-img.png" style="width: 14.75rem;height:6.14rem;position:relative;top:-2.45rem" />
    </div>
    <div class="model4 column">
      <img
        src="../../assets/image/momo/model4-title.png"
        style="width: 5.66rem;height:0.9rem;margin-top:1.02rem;margin-bottom:0.6rem"
      />
      <img src="../../assets/image/momo/model4-img.png" style="width: 10.23rem;height:3.84rem;" />
    </div>
    <div class="model5 column">
      <img
        src="../../assets/image/momo/model5-title.png"
        style="width: 6.47rem;height:1.66rem;margin-top:1rem;margin-bottom:0.8rem"
      />
      <img src="../../assets/image/momo/model5-img.png" style="width: 12.98rem;height:2.87rem;" />
    </div>
    <div class="model6 column">
      <img
        src="../../assets/image/momo/model6-title.png"
        style="width: 4.53rem;height:0.94rem;margin-top:0.93rem;margin-bottom:1rem"
      />
      <img src="../../assets/image/momo/model6-img.png" style="width: 13.77rem;height:3.2rem;" />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<style scoped>
.momo {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 5.28rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 6.75rem;
  background: #ffffff;
}
.model2 {
  position: relative;
  width: 19.2rem;
  height: 9.27rem;
}
.model3 {
  position: relative;
  width: 19.2rem;
  height: 9.29rem;
}
.model4 {
  position: relative;
  width: 19.2rem;
  height: 7.15rem;
  background: #ffffff;
}
.model5 {
  position: relative;
  width: 19.2rem;
  height: 7.33rem;
}
.model6 {
  position: relative;
  width: 19.2rem;
  height: 7.15rem;
  background: #ffffff;
}
</style>