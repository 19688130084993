<template>
  <div class="try">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div class="column form" ref="form">
      <img src="../assets/image/try/title.png" style="width:2.81rem;height:0.5rem;margin:0.6rem 0" />
      <div class="column-s" style="width:100%;margin-left:0.9rem">
        <div class="fz20 fw500">选择产品</div>
        <el-radio-group v-model="radio1" class="mt20 mb50">
          <el-radio class="radio" label="MOJ数智酒店OMS运营服务系统" size="large">MOJ数智酒店OMS运营服务系统</el-radio>
          <el-radio class="radio" label="MOMO酒店语音智能AI员工系统" size="large">MOMO酒店语音智能AI员工系统</el-radio>
          <el-radio class="radio" label="MOMO智能AI对讲系统" size="large">MOMO智能AI对讲系统</el-radio>
          <el-radio class="radio" label="美寓管家长租公寓管理系统" size="large">美寓管家长租公寓管理系统</el-radio>
          <el-radio class="radio" label="其他" size="large">其他</el-radio>
        </el-radio-group>
        <div class="fz20 fw500">合作类型</div>
        <el-radio-group v-model="radio2" class="mt20">
          <el-radio class="radio" label="酒店" size="large">酒店</el-radio>
          <el-radio class="radio" label="公寓" size="large">公寓</el-radio>
          <el-radio class="radio" label="渠道" size="large">渠道</el-radio>
          <el-radio class="radio" label="合作伙伴" size="large">合作伙伴</el-radio>
          <el-radio class="radio" label="其他" size="large">其他</el-radio>
        </el-radio-group>
        <div class="row mt10" v-if="radio2=='酒店'">
          <div class="fz16" style="width:1.4rem">使用的PMS系统</div>
          <el-input
            v-model="pms"
            placeholder="输入当前使用的PMS系统"
            clearable
            style="width: 5.36rem;margin-right:0.72rem"
          />
        </div>
        <div class="fz20 fw500 mb30 mt50">联系方式</div>
        <div class="row mb50">
          <div class="fz16" style="width:0.74rem">姓名</div>
          <el-input
            v-model="name"
            placeholder="输入姓名/称呼（必填）"
            clearable
            style="width: 6rem;margin-right:0.72rem"
          />
          <div class="fz16" style="width:0.74rem">手机</div>
          <el-input
            v-model="phone"
            placeholder="输入您的手机号码（必填）"
            clearable
            style="width: 6rem;"
            type="number"
          />
        </div>
        <div class="row mb50">
          <div class="fz16" style="width:0.74rem">单位</div>
          <el-input
            v-model="company"
            placeholder="输入您的公司名称/店名（必填）"
            clearable
            style="width: 6rem;margin-right:0.72rem"
          />
          <div class="fz16" style="width:0.74rem">职位</div>
          <el-radio-group v-model="radio3">
            <el-radio class="radio" label="负责人" size="large">负责人</el-radio>
            <el-radio class="radio" label="管理人员" size="large">管理人员</el-radio>
            <el-radio class="radio" label="运营" size="large">运营</el-radio>
            <el-radio class="radio" label="IT" size="large">IT</el-radio>
            <el-radio class="radio" label="其他" size="large">其他</el-radio>
          </el-radio-group>
        </div>
        <div class="row">
          <div class="fz16" style="width:0.74rem">城市</div>
          <el-cascader
            :options="regionData"
            @change="addressChoose"
            v-model="add"
            placeholder="省市区"
            style="width: 6rem;margin-right:0.72rem"
          />
          <div class="fz16" style="width:0.74rem">邮箱</div>
          <el-input v-model="email" placeholder="输入您的邮箱用于接收相关资料" clearable style="width: 6rem;" />
        </div>
      </div>
      <div class="btn fz18" @click="sendEmail()">确认提交</div>
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import { ref } from "vue";
import { getCurrentInstance } from "vue";
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode
} from "element-china-area-data";
import { ElLoading } from "element-plus";
import emailjs from "@emailjs/browser";
const { proxy } = getCurrentInstance();
const radio1 = ref("MOJ数智酒店OMS运营服务系统");
const radio2 = ref("酒店");
const radio3 = ref("负责人");
const name = ref();
const phone = ref();
const add = ref();
const email = ref();
const company = ref();
const address = ref();
const pms = ref();
function addressChoose(val) {
  address.value = CodeToText[val[0]] + CodeToText[val[1]] + CodeToText[val[2]];
}
function sendEmail() {
  if (name.value == null && phone.value == null && company.value == null) {
    alert("请完善表单");
    return;
  }
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)"
  });
  emailjs
    .send(
      "service_yril4of",
      "template_gi40x64",
      {
        radio1: radio1.value,
        radio2: radio2.value,
        pms: pms.value,
        radio3: radio3.value,
        name: name.value,
        phone: phone.value,
        address: address.value,
        email: email.value,
        company: company.value,
        sendemail: "info@moj100.com"
      },
      "9kSY0dkq3_Uq8lS7a"
    )
    .then(
      result => {
        loading.close();
        console.log("SUCCESS!", result.text);
        alert("申请成功");
      },
      error => {
        loading.close();
        console.log("FAILED...", error.text);
        alert("申请失败");
      }
    );
}
// function hhhh() {
//   Email.send({
//     Host : "smtp.163.com",
//     Username : "qzxt@163.com",
//     Password : "IRUHPSOOKGDNHDHG",
//     To : 'y15195965752@163.com',
//     From : "qzxt@163.com",
//     Subject : "邮件主题",
//     Body : "<h1>邮件内容</h1>"
//   }).then(message => alert(message));
// }
</script>
<style scoped>
.try {
  width: 19.2rem;
  background: #f3f5f8;
}
.form {
  background: #fff;
  width: 16rem;
  height: 9.07rem;
  margin: 0.6rem 1.6rem 1.36rem 1.6rem;
  border-radius: 0.16rem;
}
.btn {
  bottom: 1rem;
  border-radius: 0.2rem;
  background: linear-gradient(270deg, #304eeb 0%, #1a88ff 100%);
  color: #fff;
  margin-top: 0.6rem;
  padding: 0.08rem 1.14rem;
}
.radio {
  --el-color-primary: #2e52ed;
}
</style>