<template>
  <div class="meiyu">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.51rem">
      <img src="../../assets/image/meiyu/banner.png" style="width:19.2rem;height:4.5rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/meiyu/model0.png"
        style="width: 9.26rem;height:3.28rem;margin-top:1rem;"
      />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/meiyu/model1-title.png"
        style="width: 8rem;height:1.7rem;margin-top:1rem;margin-bottom:1rem"
      />
      <img src="../../assets/image/meiyu/model1-img.png" style="width: 12.31rem;height:4.07rem;" />
    </div>
    <div class="model2 column">
      <img
        src="../../assets/image/meiyu/model2-title.png"
        style="width: 3.96rem;height:0.53rem;margin-top:1rem;margin-bottom:0.97rem"
      />
      <img src="../../assets/image/meiyu/model2-img.png" style="width: 11.74rem;height:4.91rem;" />
    </div>
    <div class="model3 column">
      <img
        src="../../assets/image/meiyu/model3-title.png"
        style="width: 4.68rem;height:0.53rem;margin-top:0.98rem;margin-bottom:0.53rem"
      />
      <img src="../../assets/image/meiyu/model3-img.png" style="width: 10.1rem;height:6.63rem;" />
    </div>
    <div class="model4 column">
      <img
        src="../../assets/image/meiyu/model4-title.png"
        style="width: 5.04rem;height:0.53rem;margin-top:1.22rem;margin-bottom:0.75rem"
      />
      <img src="../../assets/image/meiyu/model4-img.png" style="width: 11.12rem;height:5.97rem;" />
    </div>
    <div class="model5 column">
      <img
        src="../../assets/image/meiyu/model5-title.png"
        style="width: 3.6rem;height:0.53rem;margin-top:1rem;margin-bottom:0.97rem"
      />
      <img src="../../assets/image/meiyu/model5-img.png" style="width: 12.15rem;height:5.8rem;" />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<style scoped>
.meiyu {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 5.28rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 8.67rem;
  background: #ffffff;
}
.model2 {
  position: relative;
  width: 19.2rem;
  height: 8.43rem;
}
.model3 {
  position: relative;
  width: 19.2rem;
  height: 8.67rem;
  background: #ffffff;
}
.model4 {
  position: relative;
  width: 19.2rem;
  height: 8.48rem;
}
.model5 {
  position: relative;
  width: 19.2rem;
  height: 9.08rem;
  background: #ffffff;
}
</style>