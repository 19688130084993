<template>
  <div class="ai">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.51rem">
      <img src="../../assets/image/ai/banner.png"  @click="toPath('/try')" style="width:19.2rem;height:4.51rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/ai/model0-title.png"
        style="width: 13.55rem;height:1.9rem;margin-top:0.81rem;margin-bottom:1.1rem"
      />
      <img src="../../assets/image/ai/model0-img.png" style="width: 12.96rem;height:1.44rem;" />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/ai/model1-title.png"
        style="width: 9.2rem;height:1.16rem;margin-top:1rem;margin-bottom:0.85rem"
      />
      <img src="../../assets/image/ai/model1-img.png" style="width: 12.8rem;height:4.65rem;" />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>
<style scoped>
.ai {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 6.55rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 8.8rem;
  background: #ffffff;
}
</style>