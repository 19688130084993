<template>
  <div class="intercom">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.51rem">
      <img src="../../assets/image/intercom/banner.png" style="width:19.2rem;height:4.5rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/intercom/model0.png"
        style="width: 12.06;height:2.73rem;margin-top:1.64rem;"
      />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/intercom/model1-title.png"
        style="width: 6.12rem;height:1.44rem;margin-top:1rem;margin-bottom:1rem"
      />
      <img src="../../assets/image/intercom/model1-img.png" style="width: 13.19rem;height:2rem;" />
    </div>
    <div class="model2 column">
      <img
        src="../../assets/image/intercom/model2-title.png"
        style="width: 2.88rem;height:0.53rem;margin-top:1rem;margin-bottom:0.65rem"
      />
      <img src="../../assets/image/intercom/model2-img.png" style="width: 15.28rem;height:4.46rem;" />
    </div>
    <div class="model3 column">
      <video class="video" :src="videoUrl" controls="controls" ref="vueRef" :poster="banner4"></video>
      <img
        src="../../assets/image/intercom/model3.png"
        style="width: 19.2rem;height:5.9rem;margin-top:1.95rem;margin-bottom:1rem"
      />
    </div>
    <div class="model4 column">
      <img
        src="../../assets/image/intercom/model4-title.png"
        style="width: 4.31rem;height:0.9rem;margin-top:0.98rem;margin-bottom:1.04rem"
      />
      <img src="../../assets/image/intercom/model4-img.png" style="width: 13.34rem;height:4.78rem;" />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import banner4 from "../../assets/image/intercom/poster.png";
import { ref, getCurrentInstance } from "vue";
const videoUrl = ref(
  "https://mojphoto.oss-cn-hangzhou.aliyuncs.com/video/intercom.mp4"
);
</script>
  <style scoped>
.intercom {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 5.28rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 6.75rem;
  background: #ffffff;
}
.model2 {
  position: relative;
  width: 19.2rem;
  height: 8.23rem;
}
.model3 {
  position: relative;
  width: 19.2rem;
  height: 8.85rem;
}

.model4 {
  position: relative;
  width: 19.2rem;
  height: 8.67rem;
  background: #ffffff;
}
.video {
  position: absolute;
  top: 1rem;
  left: 0;
  width: 10.2rem;
}
</style>