<template>
  <div class="outbound">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.5rem">
      <img src="../../assets/image/outbound/banner.png" @click="toPath('/try')" style="width:19.2rem;height:4.5rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/outbound/model0-title.png"
        style="width: 12.96rem;height:1.16rem;margin-top:1rem;margin-bottom:0.8rem"
      />
      <img src="../../assets/image/outbound/model0-img.png" style="width: 13.48rem;height:2.5rem;" />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/outbound/model1.png"
        style="width: 14.03rem;height:5.9rem;margin-top:1.04rem"
      />
    </div>
    <div class="model2 column">
      <img
        src="../../assets/image/outbound/model2-title.png"
        style="width: 1.44rem;height:0.53rem;margin-top:1.04rem;margin-bottom:0.8rem"
      />
      <img src="../../assets/image/outbound/model2-img.png" style="width: 14.02rem;height:6.03rem;" />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>
<style scoped>
.outbound {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 6.62rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 7.7rem;
  background: #ffffff;
}

.model2 {
  position: relative;
  width: 19.2rem;
  height: 9.4rem;
}
</style>