<template>
  <div class="about">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div>
      <img
        src="../assets/image/about/banner.png"
        @click="seeVideo"
        style="width:19.2rem;height:4.5rem"
      />
    </div>
    <div class="model0 column">
      <img
        src="../assets/image/about/model0-title.png"
        style="width: 10.8rem;height:1.12rem;margin-top:0.6rem;margin-bottom:0.9rem"
      />
      <img
        src="../assets/image/about/model0-img.png"
        style="width: 17.6rem;height:5.1rem;margin-left:1.6rem"
      />
    </div>
    <div class="model1 column">
      <img
        src="../assets/image/about/model1-title.png"
        style="width: 7.04rem;height:0.98rem;margin-top:1.2rem;margin-bottom:0.8rem"
      />
      <img src="../assets/image/about/model1-img.png" style="width: 13.27rem;height:3.13rem;" />
    </div>
    <div class="model2 column">
      <img
        src="../assets/image/about/model2-title.png"
        style="width: 7.92rem;height:0.94rem;margin-top:1rem;margin-bottom:0.6rem"
      />
      <img src="../assets/image/about/model2-img.png" style="width: 14.76rem;height:3.68rem;" />
    </div>
    <Right />
    <Footer />
    <el-dialog
      v-model="viewVideo"
      align-center
      :show-close="false"
      :before-close="handleCancel"
      :width="1000"
      class="dialog"
    >
      <video
        :src="videoUrl"
        :width="1000"
        autoplay="autoplay"
        controls="controls"
        :state="viewVideo"
        ref="vueRef"
      ></video>
    </el-dialog>
  </div>
</template>
<script setup>
import { getCurrentInstance } from "vue";
import { ref } from "vue";
const { proxy } = getCurrentInstance();
const videoUrl = ref(
  "https://mojphoto.oss-cn-hangzhou.aliyuncs.com/video/video.mp4"
);
const viewVideo = ref(false);

function seeVideo() {
  viewVideo.value = true;
  proxy.$refs.vueRef.play(); //播放
}

// 取消预览
function handleCancel(e) {
  viewVideo.value = false;
  proxy.$refs.vueRef.pause(); //暂停
}
</script>
<style scoped>
.about {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 8.88rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 8.01rem;
  background: #ffffff;
}
.model2 {
  position: relative;
  width: 19.2rem;
  height: 8.01rem;
}
>>> .el-dialog__header {
  padding: 0;
}
>>> .el-dialog__body {
  background: #fff;
  /* opacity: 0.5; */
  padding: 0;
}
</style>