<template>
  <div class="server">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:6.08rem">
      <img
        src="../assets/image/server/banner.png"
        @click="toPath('/try')"
        style="width:19.2rem;height:6.08rem"
      />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>
<style scoped>
.server {
  width: 19.2rem;
  background: #f3f5f8;
}
</style>