<template>
  <div class="mall">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.5rem">
      <img src="../../assets/image/mall/banner.png"  @click="toPath('/try')" style="width:19.2rem;height:4.5rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/mall/model0-title.png"
        style="width: 10.98rem;height:1.43rem;margin-top:0.81rem;margin-bottom:1.1rem"
      />
      <img src="../../assets/image/mall/model0-img.png" style="width: 13.48rem;height:2.3rem;" />
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/mall/model1-title.png"
        style="width: 10.81rem;height:1.17rem;margin-top:1rem;margin-bottom:0.70rem"
      />
      <img src="../../assets/image/mall/model1-img.png" style="width: 10.96rem;height:4.84rem;" />
    </div>
    <div class="model2 column">
      <img
        src="../../assets/image/mall/model2.png"
        style="width: 14.44rem;height:6.66rem;margin-top:1rem;"
      />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

function toPath(params) {
  router.push(params);
}
</script>
<style scoped>
.mall {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 6.56rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 8.8rem;
  background: #ffffff;
}

.model2 {
  position: relative;
  width: 19.2rem;
  height: 9.3rem;
}
</style>