<template>
  <div class="moj">
    <Header />
    <div style="padding-top:0.7rem;"></div>
    <div style="width:19.2rem;height:4.51rem">
      <img src="../../assets/image/moj/banner.png" style="width:19.2rem;height:4.5rem" />
    </div>
    <div class="model0 column">
      <img
        src="../../assets/image/moj/model0.png"
        style="width: 9.81rem;height:3.28rem;margin-top:1rem;"
      />
     
    </div>
    <div class="model1 column">
      <img
        src="../../assets/image/moj/model1-title.png"
        style="width: 5.84rem;height:0.9rem;margin-top:1rem;margin-bottom:0.6rem"
      />
      <img src="../../assets/image/moj/model1-img.png" style="width: 13.4rem;height:5.65rem;" />
    </div>
    <div class="model2 column">
      <img
        src="../../assets/image/moj/model2-title.png"
        style="width: 5.84rem;height:0.9rem;margin-top:1rem;margin-bottom:0.6rem"
      />
      <img src="../../assets/image/moj/model2-img.png" style="width: 8.77rem;height:5.72rem;" />
    </div>
    <div class="model3 column">
      <img
        src="../../assets/image/moj/model3-title.png"
        style="width: 4.13rem;height:0.53rem;margin-top:1rem;margin-bottom:0.6rem"
      />
      <img src="../../assets/image/moj/model3-img.png" style="width: 13.23rem;height:4.18rem;" />
    </div>
    <div class="model4 column">
      <img
        src="../../assets/image/moj/model4-title.png"
        style="width: 6.12rem;height:0.9rem;margin-top:1rem;margin-bottom:0.8rem"
      />
      <img src="../../assets/image/moj/model4-img.png" style="width: 12.56rem;height:4.62rem;" />
    </div>
    <div class="model5 column">
      <img
        src="../../assets/image/moj/model5-title.png"
        style="width: 6.76rem;height:0.94rem;margin-top:1rem;margin-bottom:1.06rem"
      />
      <img src="../../assets/image/moj/model5-img.png" style="width: 12.76rem;height:2.12rem;" />
    </div>
    <div class="model6 column">
      <img
        src="../../assets/image/moj/model6-bg.png"
        style="width: 19.2rem;height:4.59rem;"
      />
      <img src="../../assets/image/moj/model6-img.png" style="width: 14.72rem;height:5.98rem;position:relative;top:-2.45rem" />
    </div>
    <div class="model7 column">
      <img
        src="../../assets/image/moj/model7-title.png"
        style="width: 8.64rem;height:0.9rem;margin-top:1rem;margin-bottom:0.8rem"
      />
      <img src="../../assets/image/moj/model7-img.png" style="width: 9.82rem;height:3.66rem;" />
    </div>
    <Right />
    <Footer />
  </div>
</template>
<style scoped>
.moj {
  width: 19.2rem;
  background: #f3f5f8;
}
.model0 {
  position: relative;
  width: 19.2rem;
  height: 6.55rem;
}
.model1 {
  position: relative;
  width: 19.2rem;
  height: 8.87rem;
  background: #ffffff;
}
.model2 {
  position: relative;
  width: 19.2rem;
  height: 9.57rem;
}
.model3 {
  position: relative;
  width: 19.2rem;
  height: 7.38rem;
  background: #ffffff;
}
.model4 {
  position: relative;
  width: 19.2rem;
  height: 9.17rem;
}
.model5 {
  position: relative;
  width: 19.2rem;
  height: 6.36rem;
  background: #ffffff;
}
.model6 {
  position: relative;
  width: 19.2rem;
  height: 8.88rem;
}
.model7 {
  position: relative;
  width: 19.2rem;
  height: 7.15rem;
  background: #ffffff;
}
</style>